import React from "react";
import Layout from '../../components/layout';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemWithFinish from "../../components/itemWithFinish";
import { graphql } from "gatsby"
const puPaints = ({ data }) => (
	<Layout>
		<Row>
			<Col>
				<h1>Polyurethane Paints</h1>
			</Col>
		</Row>
		{/* PU clear */}
		<ItemWithFinish heading={"2K Polyurethane Clear"}
			data={"Pu clear is quick drying, two-pack, lead – chrome-free 2 pack acrylic pu clear coats for bus body, chassis, etc. Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"2k polyurethane clear"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			
			{/* here */}

			{/* PU lightgrey */}
		<ItemWithFinish heading={"2K Polyurethane Light Grey"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"2k polyurethane Light grey"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU glossy white */}
		<ItemWithFinish heading={"2K Polyurethane Glossy White"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Glossy white"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU apple grey */}
		<ItemWithFinish heading={"2K Polyurethane Apple Grey"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Grey"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU dove grey */}
		<ItemWithFinish heading={"2K Polyurethane Dove Grey"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Dove Grey"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU bus green */}
		<ItemWithFinish heading={"2K Polyurethane Bus Green"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Green"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU indian safform */}
		<ItemWithFinish heading={"2K Polyurethane Indian Saffron"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Saffron"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU orange red */}
		<ItemWithFinish heading={"2K Polyurethane Orange Red"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Orange Red"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU olive green */}
		<ItemWithFinish heading={"2K Polyurethane Olive Green"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Olive Green"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU aircraftgrey */}
		<ItemWithFinish heading={"2K Polyurethane Aircraft Grey"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Grey"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			<hr />
			{/* PU signal red */}
		<ItemWithFinish heading={"2K Polyurethane Signal Red"}
			data={"Developed for new work and repairs. It is easy to apply and offers excellent gloss, aesthetic and durability."}
			package={"20 ltr,4ltr,1ltr"} color={"Red"} finish={"Smooth & glossy."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs"} image={data.syntheticEnamelsMain.childImageSharp.fluid} ></ItemWithFinish>
			

	</Layout>
)

export default puPaints;
export const query = graphql`
  query {
    syntheticEnamelsMain: file(relativePath: { eq: "syntheticEnamelsMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`